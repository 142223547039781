import request, { Request } from 'superagent';

import { API_HOST } from '../constants';
import { SignUpParams } from './types';

export const signUp = async (params: SignUpParams): Promise<Request> => {
  const url = `${API_HOST}/v1/auth/signup`;

  return request.post(url).send(params).withCredentials();
};
