import { FunctionComponent, useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { dehydrate, useHydrate } from 'react-query/hydration';

import { getItem, setItem } from '@/ext/lib/storage';
import { useEventListener } from '@/ext/app/utils/useEventListener';

const QueryClientLoader: FunctionComponent = ({ children }) => {
  const queries = useMemo(() => getItem('queryClient') || '{}', []);
  const queryClient = useQueryClient();

  useHydrate(queries);
  // useEventListener('beforeunload', () =>
  //   setItem('queryClient', dehydrate(queryClient)),
  // );

  return <>{children}</>;
};

export default QueryClientLoader;
