import request from 'superagent';

import { API_HOST } from '../constants';

export const logout = async (): Promise<Response> => {
  const url = `${API_HOST}/v1/auth/logout`;

  const { body: user } = await request.post(url).withCredentials();
  return user;
};
