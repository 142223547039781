export const delay = (duration: number): Promise<null> =>
  new Promise((resolve) => setTimeout(() => resolve(null), duration));

export class TimeOutError extends Error {}

export const timeout = (
  duration: number,
  error = () => new TimeOutError('Unexpected function timeout'),
): Promise<never> =>
  delay(duration).then(() => {
    throw error();
  });
