import request, { Request } from 'superagent';

import { API_HOST } from '../constants';
import { UpdatePasswordParams } from './types';

export const updatePassword = async (
  params: UpdatePasswordParams,
): Promise<Request> => {
  const url = `${API_HOST}/v1/auth/password/update`;

  return request.put(url).send(params);
};
