import request from 'superagent';

import { API_HOST } from '../constants';
import { SignInParams, User } from './types';

export const signIn = async (params: SignInParams): Promise<User> => {
  const url = `${API_HOST}/v1/auth/login`;

  const { body: user } = await request.post(url).withCredentials().send(params);
  return user;
};
