export const getRoot = (): Document => document;

export const getRootContainer = (): HTMLElement | null =>
  getRoot()?.body.firstChild as HTMLElement | null;

/**
 * @deprecated PanelLayout has been replaced by the ColumnTemplate.
 */
export const getPrimaryPanelLayout = (): HTMLElement | null =>
  getRoot()?.getElementById('panel-layout-primary');

/**
 * Wait until a specific `readyState` has been reached.
 *
 * https://developer.mozilla.org/en-US/docs/Web/API/Document/readyState
 *
 * @param readyState - state to wait for
 * @param contentDocument - document to watch
 */
export const documentLoading = async (
  expectedState: DocumentReadyState = 'interactive',
  contentDocument: Document = document,
): Promise<void> =>
  new Promise((resolve) => {
    if (![expectedState, 'complete'].includes(contentDocument.readyState)) {
      const handler = () => {
        if (contentDocument.readyState === expectedState) {
          resolve();
          contentDocument.removeEventListener('readystatechange', handler);
        }
      };
      contentDocument.addEventListener('readystatechange', handler);
    } else {
      resolve();
    }
  });

const relativeRect = (e: DOMRect, r: DOMRect) => ({
  bottom: e.bottom - r.top,
  left: e.left - r.left,
  right: e.right - r.left,
  top: e.top - r.top,
  height: e.height,
  width: e.width,
});

export interface ScrollToProps extends Partial<ScrollIntoViewOptions> {
  onlyWhenNotVisible?: boolean;
  relativeTo?: HTMLElement | null;
}

export const scrollTo = (
  element: HTMLElement,
  { onlyWhenNotVisible, relativeTo, ...options }: ScrollToProps = {},
): void => {
  const elRect = element.getBoundingClientRect();
  const relRect = relativeTo?.getBoundingClientRect();
  const rect = relRect ? relativeRect(elRect, relRect) : elRect;
  if (
    !onlyWhenNotVisible ||
    rect.bottom > (relRect?.height || window.innerHeight) ||
    rect.top < 0
  ) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'nearest',
      ...options,
    });
  }
};

export const getHTMLElementsByClassName = (className: string): HTMLElement[] =>
  (getRootContainer()?.getElementsByClassName(className) ||
    []) as HTMLElement[];

export const getHTMLElementByClassName = (
  className: string,
): HTMLElement | undefined => getHTMLElementsByClassName(className)[0];

export const getVisibleHTMLElementsByClassName = (
  className: string,
): NodeListOf<HTMLElement> | undefined =>
  getRootContainer()?.querySelectorAll<HTMLElement>(
    `.${className}:not([hidden])`,
  );

export const getVisibleHTMLElementByClassName = (
  className: string,
): HTMLElement | undefined => getVisibleHTMLElementsByClassName(className)?.[0];

export const getWidthWithoutPadding = (element: HTMLElement): number => {
  const style = getComputedStyle(element);

  return (
    element.clientWidth -
    parseFloat(style.paddingLeft) -
    parseFloat(style.paddingRight)
  );
};

export const inIframe = (): boolean => window.self !== window.top;
