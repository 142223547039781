import { FunctionComponent, Suspense } from 'react';
import { useIsExtensionInstalled } from '@/ext/app/state/engine';
import { isBrowserSupported } from '@/ext/app/utils/isBrowserSupported';
import { isThirdPartyCookiesEnabled } from '@/utils/isThirdPartyCookiesEnabled';
import Error from './Error';
import NotSupportedError from './NotSupportedError';
import ButtonPrimary from '@/ext/app/ui/ButtonPrimary';

interface DefaultFallbackProps {
  resetError: () => void;
}

const DefaultFallback: FunctionComponent<DefaultFallbackProps> = ({
  resetError,
}) => {
  const extensionInstalled = useIsExtensionInstalled();

  const browserSupported = isBrowserSupported();

  const notSupportedError = !browserSupported && extensionInstalled;

  const refreshPage = (reset: () => void) => () => {
    reset();
    window.location.reload();
  };

  if (!isThirdPartyCookiesEnabled()) {
    return (
      <Error
        errorDescription="It appears 3rd party cookies are disabled in your browser, which is causing Waldo to not function properly."
        errorNumber={500}
      />
    );
  }

  return (
    <Suspense fallback={null}>
      {notSupportedError ? (
        <NotSupportedError />
      ) : (
        <Error
          errorDescription="Oops, something went wrong. Please refresh the page and try again"
          errorNumber={500}
        >
          <ButtonPrimary
            onClick={refreshPage(resetError)}
            sx={{
              fontSize: 20,
              fontWeight: 400,
              letterSpacing: '-2%',
              lineHeight: 1.6,
              paddingInline: 2.5,
            }}
          >
            Refresh page
          </ButtonPrimary>
        </Error>
      )}
    </Suspense>
  );
};

export default DefaultFallback;
