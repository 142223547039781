import request, { Request } from 'superagent';

import { API_HOST } from '../constants';
import { ResetPasswordParams } from './types';

export const resetPassword = async (
  params: ResetPasswordParams,
): Promise<Request> => {
  const url = `${API_HOST}/v1/auth/password/reset`;

  return request.post(url).send(params);
};
