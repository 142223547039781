import request, { Response } from 'superagent';

import { API_HOST } from '../constants';
import { User } from './types';
import * as error from '../../error';

interface ResponseError extends Error {
  status?: number;
  response?: Response;
}

export const get = async (): Promise<User | undefined> => {
  const url = `${API_HOST}/v1/auth/me`;

  try {
    const { body: user } = await request.get(url).withCredentials();
    return user;
  } catch (err) {
    // Don't log Unauthorized error
    if ((err as ResponseError).status !== 401) {
      error.log(err);
    }
    return undefined;
  }
};

export const getByUsername = async (
  username: string,
): Promise<User | undefined> => {
  const url = `${API_HOST}/v1/users/find_by_username`;

  try {
    const { body: user } = await request
      .get(url)
      .query(`username=${username}`)
      .withCredentials();
    return user;
  } catch (err) {
    error.log(err);
    return undefined;
  }
};
