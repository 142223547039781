import request from 'superagent';

import { API_HOST } from '../constants';
import { SendWaldoLinkEmailResponse } from './types';
import * as error from '../../error';

export const sendWaldoLinkEmail =
  async (): Promise<SendWaldoLinkEmailResponse> => {
    const url = `${API_HOST}/v1/auth/get-waldo`;
    try {
      const { body } = await request.post(url).withCredentials().send();

      return body;
    } catch (e) {
      error.log(e);
      return undefined;
    }
  };
