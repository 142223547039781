import * as question from './question';
import * as questionTypes from './question/types';

import * as nps from './nps';

import * as user from './user';
import * as userTypes from './user/types';

import * as team from './team';
import * as teamTypes from './team/types';

export namespace WaldoAPI {
  export interface Error {
    status: number;
    response?: {
      body?: {
        message?: string;
      };
    };
  }

  export type Question = questionTypes.Question;
  export type Answer = questionTypes.Answer;
  export type UserAnswer = questionTypes.UserAnswer;

  export type User = userTypes.User;
  export type UserResetPasswordParams = userTypes.ResetPasswordParams;
  export type UserResendConfirmationParams = userTypes.ResendConfirmationParams;
  export type UserSendWaldoLinkEmailResponse =
    userTypes.SendWaldoLinkEmailResponse;
  export type UserSignInParams = userTypes.SignInParams;
  export type UserSignUpParams = userTypes.SignUpParams;
  export type UserUpdatePasswordParams = userTypes.UpdatePasswordParams;

  export type Team = teamTypes.Team;
  export type TeamMember = teamTypes.TeamMember;
}

export default { question, user, nps, team };
