import { isEmpty } from 'lodash';
import { ParsedUrlQuery } from 'querystring';
import localstorage from './localstorage';
import { WaldoVersion } from './waldoVersion';

export const isWaldoInstalled = (): boolean =>
  typeof document !== 'undefined' &&
  !!document.documentElement.getAttribute('waldo-installed-version');

export const getWaldoInstalledVersion = (): WaldoVersion | undefined => {
  try {
    const fromTag = document.documentElement.getAttribute(
      'waldo-installed-version',
    );
    if (!fromTag) {
      return undefined;
    }
    return new WaldoVersion(fromTag);
  } catch (_) {
    return undefined;
  }
};

export const isChromeFamily = (): boolean =>
  typeof window !== 'undefined' &&
  /Chrome/.test(navigator.userAgent) &&
  /Google Inc/.test(navigator.vendor);

export const isBrave = (): boolean =>
  typeof window !== 'undefined' &&
  typeof window.navigator !== 'undefined' &&
  typeof (window.navigator as any).brave !== 'undefined';

export const isEdge = (): boolean =>
  typeof window !== 'undefined' && /Edg/.test(navigator.userAgent);

export const isFirefox = (): boolean =>
  typeof window !== 'undefined' && /Firefox/.test(navigator.userAgent);

export const storeUtmTags = (query: ParsedUrlQuery): void => {
  const utm = Object.entries(query).reduce((acc, [key, value]) => {
    if (!key.startsWith('utm_')) {
      return acc;
    }
    return { ...acc, [key]: value };
  }, {});

  if (!isEmpty(utm)) {
    localstorage.set('utm', JSON.stringify(utm));
  }
};

export const getUtmTags = (): string | null => localstorage.get('utm');

export const clearUtmTags = (): void => localstorage.remove('utm');
