import { DefaultOptions } from 'react-query';

export const DATE_FORMAT = 'MMM D, YYYY';

export const DAY_MS = 1000 * 60 * 60 * 24;

export const QUERY_CACHE_DEFAULT_OPTIONS: DefaultOptions = {
  queries: {
    refetchOnWindowFocus: false,
  },
};

export const EMAIL_FIELD_PATTERN_MESSAGE = 'Your email address looks invalid.';

export const PASSWORD_FIELD_PATTERN_MESSAGE =
  'Your password must be at least 8 characters.';

export const EMAIL_PATTERN =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const SKIM_FILTER_SHORTCUTS: Record<string, string> = {
  datapoints: 'd',
  all: 'a',
  emails: 'e',
  dates: 't',
  quotes: 'q',
};

export const CHROME_EXTENSION_URL = 'http://bit.ly/waldo3';

export const FIREFOX_EXTENSION_URL =
  'https://addons.mozilla.org/en-US/firefox/addon/waldo-search-like-a-pro';

export const EXTENSION_INSTALLED_KEY = 'extensionInstalled';
