import { isFirefox } from '@/lib/utils';

const MINIMUM_CHROME_VERSION = 102;

export const isBrowserSupported = (): boolean => {
  const firefox = isFirefox();

  if (firefox) {
    return true;
  }

  // Only check for chrome browser
  const versionString = navigator.userAgent.match(
    /Chrom(e|ium)\/([0-9]+)\./,
  )?.[2];

  const version = versionString ? parseInt(versionString, 10) : undefined;

  return !!version && version >= MINIMUM_CHROME_VERSION;
};
