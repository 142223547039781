/* eslint-disable no-console */

import { COMMIT_SHA, WALDO_APP_VERSION } from '../app/utils/version';
import { User } from '../types';
import { WaldoAPI } from './api';

export const DISABLE_TRACKING_FOR_EMAIL_DOMAIN = 'waldo.fyi';
const TRACKING_ENABLED = process.env.NEXT_PUBLIC_TRACKING_ENABLED === 'true';

export type Meta = Record<string, unknown>;

export interface WaldoEvent<T extends Meta> {
  event: string;
  meta?: T;
}

export class EventAPI extends WaldoAPI {
  async event<T extends Meta>(body: WaldoEvent<T>): Promise<void> {
    return this.request({ method: 'POST', path: '/v1/events', body });
  }
}

const api = new EventAPI();
export const shouldTrackUser = (user: User | undefined): boolean =>
  !user?.email?.endsWith(DISABLE_TRACKING_FOR_EMAIL_DOMAIN);

const sendToServer = async (props: {
  event: string;
  userId?: string;
  meta?: Meta;
}): Promise<void> => {
  try {
    await api.event({
      event: props.event,
      meta: props.meta,
    });
  } catch (err) {
    console.error('Unable to track →', err);
  }
};

export const trackUserEvent = <T extends Meta>(
  event: string,
  meta?: T,
): void => {
  const enabled = TRACKING_ENABLED;
  console.groupCollapsed(
    '🧐Track\t\t→',
    `${event}${meta?.target ? ` (${meta?.target})` : ''}`,
    !enabled ? '[blocked]' : '',
  );
  const eventMeta: Meta = {
    ...meta,
    width: globalThis.window?.innerWidth,
    height: globalThis.window?.innerHeight,
    commit: COMMIT_SHA,
    userAgent: navigator.userAgent,
    path: window.trackingPath,
  };
  if (WALDO_APP_VERSION) {
    eventMeta.version = WALDO_APP_VERSION;
  }
  console.log('Meta', JSON.stringify(eventMeta));
  if (enabled) {
    sendToServer({
      event,
      meta: eventMeta,
    });
  }
  console.groupEnd();
};
