import request from 'superagent';

import { API_HOST } from '../constants';
import { NpsQuestion } from './types';

export const getQuestions = async (): Promise<NpsQuestion[]> => {
  const url = `${API_HOST}/v1/nps/questions`;

  const { body } = await request.get(url).withCredentials();

  return body;
};
