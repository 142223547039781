import { useMemo } from 'react';
import {
  createTheme,
  responsiveFontSizes,
  Theme,
  ThemeOptions,
} from '@mui/material';

import { usePreferredTheme } from '@/styles/themes/state';
import darkSystemTheme from '@/styles/themes/dark';
import system from '@/styles/themes/system';

const baseOptions: ThemeOptions = {
  typography: {
    h1: {
      fontSize: 56,
      letterSpacing: '-0.03em',
      lineHeight: 1.14285714,
      fontWeight: 800,
    },

    h2: {
      fontSize: 48,
      letterSpacing: '-0.03em',
      lineHeight: 1.16666667,
      fontWeight: 800,
    },

    h3: {
      fontSize: 32,
      letterSpacing: '-0.03em',
      lineHeight: 1.25,
      fontWeight: 800,
    },

    h4: {
      fontSize: 24,
      letterSpacing: '-0.03em',
      lineHeight: 1.33333333,
      fontWeight: 800,
    },

    h5: {
      fontSize: 24,
      letterSpacing: '-0.02em',
      lineHeight: 1.33333333,
      fontWeight: 400,
    },

    h6: {
      fontSize: 24,
      letterSpacing: '-0.02em',
      lineHeight: 1.33333333,
      fontWeight: 300,
    },

    body1: {
      fontSize: 18,
      lineHeight: 1.333333333,
      fontWeight: 400,
    },

    body2: {
      fontSize: 16,
      lineHeight: 1.5,
      fontWeight: 400,
    },

    button: {
      fontSize: 24,
      fontWeight: 400,
      lineHeight: 1,
    },

    caption: {
      fontFamily: 'Fraktion Mono',
      fontSize: 16,
      letterSpacing: '-0.04em',
      lineHeight: 1.5,
      fontWeight: 400,
    },

    subtitle1: {
      fontSize: 20,
      letterSpacing: '-0.02em',
      lineHeight: 1.2,
      fontWeight: 800,
    },

    subtitle2: {
      fontSize: 15,
      letterSpacing: '-0.02em',
      lineHeight: 1.6,
      fontWeight: 500,
    },
  },

  // TODO: eventuall remove these...
  components: {
    MuiDivider: {
      styleOverrides: {
        root: {
          minHeight: 1,
        },
      },
    },

    MuiFormHelperText: {
      styleOverrides: {
        root: {
          paddingTop: '1rem',
          fontFamily: 'Fraktion Mono',
        },
      },
    },

    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: '1.375rem',
          fontWeight: 800,
          marginBottom: '1rem',
          display: 'block',
        },
      },
    },

    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
    },

    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        inputProps: { autoComplete: 'off' },
        fullWidth: true,
      },
    },
  },
};

export default createTheme(system, baseOptions);

export const dark = responsiveFontSizes(
  createTheme(darkSystemTheme, baseOptions),
);

export const usePreferredSiteTheme = (removeMediaQueries = true): Theme => {
  const preferredTheme = usePreferredTheme();

  return useMemo(
    () => responsiveFontSizes(createTheme(preferredTheme, baseOptions)),
    [preferredTheme, removeMediaQueries],
  );
};
