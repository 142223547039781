import { createTheme, ThemeOptions } from '@mui/material';

import { MINIMUM_LARGE_SCREEN_SIZE } from '@/styles/constants';
import { getOverrides } from './overrides';

export const baseOptions: ThemeOptions = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 450,
      md: 768,
      lg: MINIMUM_LARGE_SCREEN_SIZE,
      xl: 1856,
    },
  },

  shape: {
    borderRadius: 5,
  },

  spacing: 16,

  typography: {
    fontFamily: ['Inter', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),

    body1: {
      fontSize: 14,
      lineHeight: 1.42857143,
    },

    body2: {
      fontSize: 13,
      lineHeight: 1.23076923,
    },

    button: {
      fontSize: 13,
      fontWeight: 800,
      lineHeight: 1.23076923,
      textTransform: 'none',
    },

    caption: {
      fontSize: 12,
      fontWeight: 500,
      lineHeight: 1.333333333,
    },

    h1: {
      fontSize: 24,
      fontWeight: 900,
      lineHeight: 1,
    },

    h2: {
      fontSize: 24,
      fontWeight: 500,
      lineHeight: 1,
    },

    h3: {
      fontSize: 20,
      fontWeight: 500,
      lineHeight: 1.2,
    },

    h4: {
      fontSize: 17,
      fontWeight: 800,
      lineHeight: 1.41176471,
    },

    h5: {
      fontSize: 17,
      fontWeight: 400,
      lineHeight: 1.41176471,
    },

    h6: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: 1.25,
    },

    overline: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: 1.333333333,
      textTransform: 'none',
    },

    subtitle1: {
      fontSize: 14,
      fontWeight: 800,
      lineHeight: 1.42857143,
    },

    subtitle2: {
      fontSize: 14,
      fontWeight: 600,
      lineHeight: 1.42857143,
    },
  },
};

export const extendSystemTheme = (options: ThemeOptions) => {
  const base = createTheme(baseOptions, options);

  return createTheme(base, getOverrides(base));
};

export default createTheme(baseOptions);
