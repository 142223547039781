import * as error from '../error';

const isLocalStorageEnabled = (): boolean =>
  typeof window !== 'undefined' &&
  typeof window.navigator !== 'undefined' &&
  window.navigator.cookieEnabled &&
  typeof window.localStorage !== 'undefined';

const set = (key: string, value: string): void => {
  if (!isLocalStorageEnabled()) {
    return;
  }

  try {
    localStorage.setItem(key, value);
  } catch (err) {
    error.log(err);
  }
};

const get = (key: string): string | null => {
  if (!isLocalStorageEnabled()) {
    return null;
  }

  let res: string | null = null;
  try {
    res = localStorage.getItem(key);
  } catch (err) {
    error.log(err);
  }
  return res;
};

const remove = (key: string): void => {
  if (!isLocalStorageEnabled()) {
    return;
  }

  try {
    localStorage.removeItem(key);
  } catch (err) {
    error.log(err);
  }
};

export default { get, set, remove };
