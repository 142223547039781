import { AtomEffect } from 'recoil';

import {
  getItem,
  setItem,
  removeItem,
  getLocalStorageItem,
  setLocalStorageItem,
  removeLocalStorageItem,
  getSyncStorageItem,
  removeSyncStorageItem,
  setSyncStorageItem,
} from '../../lib/storage';

export const sessionStorageEffect = <T>({
  node,
  setSelf,
  onSet,
}: Parameters<AtomEffect<T>>[0]): void => {
  const { key } = node;
  const savedValue = getItem<T>(key);
  if (savedValue !== null) {
    setSelf(savedValue);
  }

  onSet((newValue) => {
    if (newValue === undefined) {
      removeItem(key);
    } else {
      setItem(key, newValue);
    }
  });
};

export const sessionStorageSavingEffect = <T>({
  node,
  onSet,
}: Parameters<AtomEffect<T>>[0]): void => {
  const { key } = node;

  onSet((newValue, _, isReset) => {
    if (isReset || newValue === undefined) {
      removeItem(key);
    } else {
      setItem(key, newValue);
    }
  });
};

export const apiStorageEffect = <T>({
  node,
  setSelf,
  onSet,
  trigger,
}: Parameters<AtomEffect<T>>[0]): void => {
  const { key } = node;

  const loadSavedValue = async () => {
    const savedValue = await getSyncStorageItem<T>(key);
    if (savedValue !== undefined && savedValue !== null) {
      setSelf(savedValue);
    }
  };

  if (trigger === 'get') {
    loadSavedValue();
  }

  onSet((newValue, _, isReset) => {
    if (isReset || newValue === undefined) {
      removeSyncStorageItem(key);
    } else {
      setSyncStorageItem(key, newValue);
    }
  });
};

export const localStorageEffect = <T>({
  node,
  setSelf,
  onSet,
}: Parameters<AtomEffect<T>>[0]): void => {
  const { key } = node;
  const savedValue = getLocalStorageItem<T>(key);
  if (savedValue !== undefined && savedValue !== null) {
    setSelf(savedValue);
  }

  onSet((newValue, _, isReset) => {
    if (isReset || newValue === undefined) {
      removeLocalStorageItem(key);
    } else {
      setLocalStorageItem(key, newValue);
    }
  });
};
