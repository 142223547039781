import { ParsedUrlQueryInput } from 'querystring';

import { Finding, Highlight } from './app/state/findings/types';

export interface WaldoAPIRequestParams<T = undefined> {
  method: string;
  path: string;
  body?: T;
  options?: Partial<RequestInit>;
  query?: ParsedUrlQueryInput;
}

/**
 * Messages that the `app/` will listen for.
 */
export type AppMessage =
  | { type: 'ENABLED'; enabled: boolean }
  | { type: 'SAVE_HIGHLIGHT'; highlight: string; url: string }
  | { type: 'SAVE_IMAGE'; url: string; image: string }
  | { type: 'SAVE_PAGE'; url: string }
  | { type: 'TOGGLE_VISIBILITY' }
  | { type: 'USER_UPDATE'; user?: User };

/**
 * Messages that the `messageExternal` will listen for.
 */
export type ExternalMessage<B = undefined> =
  | { type: 'PING' }
  | { type: 'FETCH_PAGE'; payload: { url: string; userAgent?: string } }
  | { type: 'CLEAR_FETCH_CACHE' }
  | { type: 'GET_OMNIBOX_ENABLED' }
  | { type: 'OPEN_TAB'; url: string; active?: boolean }
  | { type: 'SET_LAST_SEARCH_QUERY'; value: string }
  | { type: 'SET_SIDEPANEL_VISIBILITY'; value: boolean }
  | { type: 'WALDO_API_REQUEST'; value: WaldoAPIRequestParams<B> }
  | { type: 'TOGGLE_OMNIBOX_ENABLED' }
  | { type: 'SET_OMNIBOX_ENABLED'; value: boolean }
  | { type: 'GET_OMNIBOX_ENABLED' }
  | { type: 'GET_ADS_ENABLED' };

/**
 * Messages that the Notepad page will listen for.
 */
export type EditorMessage =
  | { type: 'ADD_FINDING'; finding: Omit<Finding, 'findingId'> }
  | { type: 'REMOVE_FINDING'; finding: Finding }
  | { type: 'SET_SELECTED_NOTE_ID'; noteId: number };

/**
 * Messages that `window.parent` (extension contentScript) will listen for.
 */
export type ParentMessage =
  | { type: 'WALDO:COPY_TO_CLIPBOARD'; value: string }
  | { type: 'WALDO:HIDE' }
  | { type: 'WALDO:DELETE_NOTE' }
  | { type: 'WALDO:HIGHLIGHTS'; highlights: Highlight[] }
  | { type: 'WALDO:LAST_SEARCH_QUERY'; searchQuery: string }
  | {
      type: 'WALDO:NAVIGATE_TO_SEARCH_RESULTS';
      searchParams?: Record<string, string>;
      searchQuery?: string;
      target?: string;
    }
  | { type: 'WALDO:OPEN_NOTEPAD' }
  | { type: 'WALDO:SET_GOOGLE_HIDE'; value: boolean | number }
  | { type: 'WALDO:STATUS'; status: boolean }
  | { type: 'WALDO:GET_ADS_ENABLED' }
  | { type: 'WALDO:SET_ADS_ENABLED'; value: boolean };

/**
 * The body of a feedback post.
 */
export interface FeedbackBody {
  browserDetails?: string;
  feedback: string;
  searchQuery?: string;
  type: FeedbackTypes;
  version?: string;
}

/**
 * The body of a talk to us post.
 */
export interface TalkToUsBody {
  name: string;
  email: string;
  company: string;
  phone: string;
  needs: string;
}

/**
 * Generic format for input options in forms (select, radio list, etc..)
 */
export interface InputOption<T = unknown> {
  label: string;
  value: T;
}

/**
 * Nps Questions
 */
export interface NpsQuestion {
  npsQuestionId: number;
  title: string;
  npsAnswers: NpsAnswer[];
  type: 'select' | 'numeric' | 'text';
  order: number;
}

export interface UserNpsAnswer {
  questionId: number;
  answerId?: number;
  answerText?: string;
  answerScore?: number;
}

export interface NpsAnswer {
  npsAnswerId: number;
  title: string;
  npsQuestionId: number;
}

/**
 * Onboarding Questions
 */
export interface OnboardingQuestion {
  questionId: number;
  title: string;
  answers: OnboardingAnswer[];
  order: number;
}

export interface OnboardingAnswer {
  answerId: number;
  title: string;
  allowCustomAnswer?: boolean;
  dependsOn?: number;
  questionId: number;
}

export interface UserOnboardingAnswer {
  answerId: number;
  customAnswer?: string;
}

export interface UserEmail {
  emailId: number;
  email: string;
  confirmed: boolean;
  createdAt: Date;
}

export interface PresignedUrl {
  url: string;
}

export type Subscription = {
  subscriptionId: number;
  userId: number;
  teamId?: number | null;
  stripeSubscriptionId: string;
  name: SubscriptionType;
  startDate: string;
  endDate: string;
  trial: boolean;
  status: string;
  billingInterval: 'month' | 'year';
  licenseLimit: number;
  createdAt: string;
  updatedAt: string;
};

export enum SubscriptionType {
  lite = 'lite',
  pro = 'pro',
  ultra = 'ultra',
  ai = 'ai',
  freelance = 'freelance',
  teams = 'teams',
  enterprise = 'enterprise',
}

/**
 * Our User model.
 */
export interface User {
  thumbnail?: string | null;
  email: string;
  subscription: SubscriptionType;
  trialEndDate?: string;
  subscriptionStartDate?: string;
  subscriptionEndDate?: string;
  billingInterval?: 'month' | 'year';
  onTrial: boolean;
  name?: string;
  userAnswers?: string[];
  userId: number;
  customerId?: string;
  username: string;
  emails: UserEmail[];
  subscriptions: Subscription[];
}

/**
 * Different types of feedback that a user can provide.
 */
export enum FeedbackTypes {
  BUG = 'Bug',
  IDEA = 'Idea',
  OTHER = 'Other',
}

export enum WaldoAPIMethod {
  LOGOUT,
  CHECK_NPS,
  GET_NPS_QUESTIONS,
  ANSWER_NPS,
  CANCEL_NPS,
  GET_ONBOARDING_QUESTIONS,
  ANSWER_ONBOARDING,
  EVENT,
  FEEDBACK,
}

export interface IdeateProject {
  projectId: number;
  title: string;
  teamId?: number;
  data?: any;
  createdAt: Date;
  userId: number;
}
