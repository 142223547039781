import { extendSystemTheme } from '../system';
import colors, { base } from './colors';

const { fill, text } = colors;

export default extendSystemTheme({
  palette: {
    mode: 'dark',

    ...colors,

    // TODO: eventually remove?
    // For buttons
    neutral: {
      contrastText: '#FFFFFF',
      dark: fill?.high || '',
      light: fill?.medium || '',
      main: text?.disabled || '',
    },

    // TODO: eventually remove?
    contrast: {
      contrastText: '#000000',
      dark: base,
      light: fill?.medium || '',
      main: fill?.high || '',
    },
  },
});
