import { BaseSyntheticEvent, useRef, useEffect } from 'react';

export interface IParentElement {
  addEventListener(
    type: string,
    listener: EventListenerOrEventListenerObject,
    options?: boolean | AddEventListenerOptions,
  ): void;
  removeEventListener(
    type: string,
    listener: EventListenerOrEventListenerObject,
    options?: boolean | EventListenerOptions,
  ): void;
}

export function useEventListener<E extends Event | BaseSyntheticEvent>(
  eventName: string,
  handler: (event: E) => void,
  parentElement: IParentElement | null = null,
  useCapture = false,
): void {
  // Create a ref that stores handler
  const savedHandler = useRef<(e: E) => void>();

  // Update ref.current value if handler changes.
  // This allows our effect below to always get latest handler ...
  // ... without us needing to pass it in effect deps array ...
  // ... and potentially cause effect to re-run every render.
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const element = parentElement || window;
    // Make sure parentElement supports addEventListener
    const isSupported = element && element.addEventListener;

    if (!isSupported) {
      return undefined;
    }

    // Create event listener that calls handler function stored in ref
    const eventListener = (event: E) => {
      savedHandler.current?.(event);
    };

    element.addEventListener(
      eventName,
      eventListener as EventListener,
      useCapture,
    );

    return () => {
      if (element.removeEventListener) {
        element.removeEventListener(
          eventName,
          eventListener as EventListener,
          useCapture,
        );
      }
    };
  }, [eventName, parentElement, useCapture]);
}
