import request, { Request } from 'superagent';

import { API_HOST } from '../constants';
import { ResendConfirmationParams } from './types';

export const resendConfirmation = async (
  params: ResendConfirmationParams,
): Promise<Request> => {
  const url = `${API_HOST}/v1/auth/resend-confirmation`;

  return request.post(url).send(params);
};
