import { Breadcrumb } from '@sentry/nextjs';

/* eslint-disable no-console */
const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

if (!SENTRY_DSN) {
  // eslint-disable-next-line no-console
  console.error('Sentry DSN is not set');
}

const beforeSend = (event: any) => {
  if (process.env.NODE_ENV === 'development') {
    console.groupCollapsed(
      '🤒Sentry log\t\t→',
      event.message ? `Message: ${event.message}` : undefined,
    );
    if (event.exception && event.exception.values?.length > 0) {
      event.exception.values.forEach(({ type, value, mechanism }: any) => {
        console.log(
          'Exception',
          `${type}: ${value} / ${JSON.stringify(mechanism)}`,
        );
      });
    }
    console.log('Contexts', event.contexts);
    console.groupEnd();
  }
  return event;
};

const sentryOptions = {
  dsn: SENTRY_DSN,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.3,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  ignoreErrors: [],
  beforeSend,
  beforeBreadcrumb(breadcrumb: Breadcrumb) {
    // Don't send the `debug` breadcrumb type
    return breadcrumb.level === 'debug' ? null : breadcrumb;
  },
};

export default sentryOptions;
