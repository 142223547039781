import { useQuery, UseQueryResult } from 'react-query';
import { useSetRecoilState } from 'recoil';
import { getLocalStorageItem, setLocalStorageItem } from '@/ext/lib/storage';

import api, { WaldoAPI } from '../waldo-api';
import { userAsyncState, ValueType as User } from '@/ext/app/state/user';

// TODO: Remove one useUser hook as part of cleanup effort
export const useUser = (): WaldoAPI.User | undefined => {
  const setUser = useSetRecoilState(userAsyncState);

  const { data: user } = useQuery('user', api.user.get, {
    onSuccess(data) {
      if (data) {
        // Don't use this local storage item for anything else
        setLocalStorageItem('sync-user', data);
        setUser(data as unknown as User);
      }
    },
  });

  const storedUser = getLocalStorageItem<WaldoAPI.User>('sync-user');

  return storedUser || user;
};

export const useUserByUsername = (
  username?: string,
): UseQueryResult<WaldoAPI.User | undefined> =>
  useQuery(
    ['user', username],
    () => (username ? api.user.getByUsername(username) : undefined),
    { enabled: !!username },
  );
