import { extendSystemTheme } from '../system';
import colors, { base } from './colors';

const { fill } = colors;

export default extendSystemTheme({
  palette: {
    mode: 'light',

    ...colors,

    // For buttons
    neutral: {
      contrastText: '#FFFFFF',
      dark: fill?.high || '',
      light: fill?.medium || '',
      main: fill?.high || '',
    },

    contrast: {
      contrastText: '#FFFFFF',
      dark: base,
      light: fill?.medium || '',
      main: fill?.high || '',
    },
  },
});
