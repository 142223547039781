import { lighten, PaletteOptions } from '@mui/material';

export const base = '#262626';

const colors: PaletteOptions = {
  background: {
    contrast: '#28292C',
    default: '#FFFFFF',
    menu: '#FFFFFF',
    paper: lighten(base, 0.98), // #262626 - 2%
  },

  primary: {
    contrastText: '#FFFFFF',
    light: '#48A2A2',
    main: '#1B8C8C',
  },

  secondary: {
    contrastText: '#FFFFFF',
    light: lighten('#48A2A2', 0.96),
    main: lighten('#48A2A2', 0.9),
    dark: lighten('#48A2A2', 0.15),
  },

  tertiary: {
    contrastText: '#FFFFFF',
    dark: '#9A164E',
    main: '#D40A60',
    light: '#FDC9DF',
  },

  text: {
    primary: base, // #262626
    secondary: lighten(base, 0.2), // #262626 - 80%
    disabled: lighten(base, 0.4), // #262626 - 60%
    contrast: '#FFFFFF',
  },

  helper: lighten(base, 0.55), // #262626 - 45%

  error: {
    main: '#9A164E',
  },

  fill: {
    high: lighten(base, 0.15), // #262626 - 85%
    medium: lighten(base, 0.85), // #262626 - 15%
    disabled: lighten(base, 0.95), // #262626 - 5%
  },

  info: {
    contrastText: '#FFFFFF',
    dark: '#9A164E',
    light: '#E3AC38',
    main: '#154579',
  },

  icon: {
    high: lighten(base, 0.4), // #262626 - 60%
    medium: lighten(base, 0.6), // #262626 - 40%
    disabled: lighten(base, 0.75), // #262626 - 25%
  },

  line: {
    high: lighten(base, 0.8), // #262626 - 20%
    medium: lighten(base, 0.88), // #262626 - 12%
    disabled: lighten(base, 0.93), // #262626 - 7%
  },

  warning: {
    contrastText: '#FFFFFF',
    light: '#FAC034',
    main: '#9A164E',
  },
};

export default colors;
