/* eslint-disable no-console */

import { inWindow } from '@/utils/inWindow';
import { StorageAPI } from '../../rpc/storage';
import { log } from '../utils/log';

const storage = new StorageAPI();

export const getItem = <Value extends unknown>(key: string): Value | null => {
  if (!inWindow()) {
    return null;
  }

  let value;

  try {
    value = sessionStorage.getItem(key);

    if (value !== undefined && value !== null) {
      value = JSON.parse(value);
    }
  } catch (err) {
    log('Failed to use sessionStorage:', err);
  }

  return value !== undefined ? value : null;
};

export const removeItem = (key: string): void => {
  if (!inWindow()) {
    return;
  }

  try {
    sessionStorage.removeItem(key);
  } catch (err) {
    log('Failed to use sessionStorage:', err);
  }
};

export const setItem = (key: string, value: unknown): void => {
  if (!inWindow()) {
    return;
  }

  try {
    sessionStorage.setItem(key, JSON.stringify(value));
  } catch (err) {
    log('Failed to use sessionStorage:', err);
  }
};

export const getSyncStorageItem = async <T = unknown>(
  key: string,
): Promise<T | null> => {
  try {
    return await storage.get<T>(key);
  } catch (err) {
    log('Failed to use syncStorage:', err);
  }

  return null;
};

export const removeSyncStorageItem = async (key: string): Promise<void> => {
  try {
    await storage.remove(key);
  } catch (err) {
    log('Failed to use syncStorage:', err);
  }
};

export const setSyncStorageItem = async (
  key: string,
  value: unknown,
): Promise<void> => {
  try {
    await storage.set({ [key]: value });
  } catch (err) {
    log('Failed to use syncStorage:', err);
  }
};

export const setLocalStorageItem = (key: string, value: unknown): void => {
  if (!inWindow()) {
    return;
  }

  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (err) {
    log('Failed to use localStorage:', err);
  }
};

export const getLocalStorageItem = <Value extends unknown>(
  key: string,
): Value | null => {
  if (!inWindow()) {
    return null;
  }

  let value;

  try {
    value = localStorage.getItem(key);

    if (value !== undefined && value !== null) {
      value = JSON.parse(value);
    }
  } catch (err) {
    log('Failed to use localStorage:', err);
  }

  return value !== undefined ? value : null;
};

export const removeLocalStorageItem = (key: string): void => {
  if (!inWindow()) {
    return;
  }

  try {
    localStorage.removeItem(key);
  } catch (err) {
    log('Failed to use localStorage:', err);
  }
};
