import { WaldoAPI } from '../ext/lib/api';

export class StorageAPI extends WaldoAPI {
  async get<T = unknown>(key: string | null): Promise<T> {
    const { value } = await this.request<{ value: T }>({
      method: 'GET',
      path: '/v1/storage',
      query: { key },
    });

    return value;
  }

  async set(values: Record<string, unknown>): Promise<void> {
    return this.request({
      method: 'PUT',
      path: '/v1/storage',
      body: values,
    });
  }

  async clear(): Promise<void> {
    return this.request({
      method: 'DELETE',
      path: '/v1/storage',
    });
  }

  async remove(key: string): Promise<void> {
    return this.request({
      method: 'DELETE',
      path: `/v1/storage/${key}`,
    });
  }
}
