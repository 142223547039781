import { FunctionComponent, useMemo } from 'react';

import {
  CssBaseline,
  Theme,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material';

import { usePreferredTheme } from '@/styles/themes/state';
import { usePreferredNotesTheme } from '@/styles/themes/notes';
import { usePreferredSiteTheme } from '@/styles/themes/site';
import light from '@/styles/themes/light';
import dark from '@/styles/themes/dark';

export interface ThemeProviderProps {
  noBaseline?: boolean;
  removeMediaQueries?: boolean;
  theme?: 'dark' | 'light' | 'notes' | 'site' | Theme;
}

const ThemeProvider: FunctionComponent<ThemeProviderProps> = ({
  children,
  noBaseline = false,
  removeMediaQueries = false,
  theme: defaultTheme,
}) => {
  const notesTheme = usePreferredNotesTheme(removeMediaQueries);
  const siteTheme = usePreferredSiteTheme();
  const systemTheme = usePreferredTheme();

  const theme = useMemo(() => {
    if (typeof defaultTheme === 'string') {
      switch (defaultTheme) {
        case 'dark':
          return dark;

        case 'light':
          return light;

        case 'site':
          return siteTheme;

        case 'notes':
          return notesTheme;

        default:
      }
    }

    return defaultTheme || systemTheme;
  }, [defaultTheme, notesTheme, siteTheme, systemTheme]);

  return (
    <MuiThemeProvider theme={theme}>
      {!noBaseline && <CssBaseline />}
      {children}
    </MuiThemeProvider>
  );
};

export default ThemeProvider;
