import { FunctionComponent, useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { RecoilRoot, useSetRecoilState } from 'recoil';
import { GoogleOAuthProvider } from '@react-oauth/google';

import QueryClientLoader from './QueryClientLoader';
import RecoilLogger from './RecoilLogger';
import { QUERY_CACHE_DEFAULT_OPTIONS } from '@/constants';
import {
  extensionInstalledState,
  isExtensionInstalled,
} from '@/ext/app/state/engine';
import { ensure } from '@/ext/utils/ensure';

const GOOGLE_CLIENT_ID = ensure(process.env.NEXT_PUBLIC_GOOGLE_DOCS_CLIENT_ID);

export const queryClient = new QueryClient({
  defaultOptions: QUERY_CACHE_DEFAULT_OPTIONS,
});

interface ComponentProps {}

const RecoilResets: FunctionComponent = () => {
  const setExtensionInstalledState = useSetRecoilState(extensionInstalledState);

  const resetExtensionInstalledState = () => {
    isExtensionInstalled().then((installed) => {
      setExtensionInstalledState(installed);
    });
  };

  useEffect(() => {
    window.addEventListener('focus', resetExtensionInstalledState);

    return () => {
      window.removeEventListener('focus', resetExtensionInstalledState);
    };
  }, []);

  return null;
};

const Dependencies: FunctionComponent<ComponentProps> = ({ children }) => (
  <RecoilRoot>
    <RecoilResets />
    {process.env.NODE_ENV === 'development' && <RecoilLogger />}
    <QueryClientProvider client={queryClient}>
      <QueryClientLoader>
        <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
          {children}
        </GoogleOAuthProvider>
      </QueryClientLoader>
    </QueryClientProvider>
  </RecoilRoot>
);

export default Dependencies;
