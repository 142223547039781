import ThemeProvider from '@/components/providers/ThemeProvider';
import { Waldo } from '@/ext/app/icons/Waldo';
import ButtonPrimary from '@/ext/app/ui/ButtonPrimary';
import { sendMessage } from '@/ext/app/utils/sendBackgroundMessage';
import { Box, styled, Typography } from '@mui/material';
import Head from 'next/head';
import { FunctionComponent } from 'react';

const WaldoLogo = styled(Waldo)(({ theme }) => ({
  color: theme.palette.icon?.disabled,
  height: theme.spacing(1.9),
  width: theme.spacing(7.5),
}));

const NotSupportedError: FunctionComponent = () => {
  return (
    <ThemeProvider>
      <Head>
        <title>Waldo / Error</title>
      </Head>
      <Box
        height="100vh"
        width="100vw"
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <WaldoLogo />
        <Box my={2} maxWidth={700}>
          <Typography color="text.secondary" fontSize={24} textAlign="center">
            Your browser version is not supported. Please update your browser to
            the latest version or disable the Waldo extension.
          </Typography>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default NotSupportedError;
