import { NextPage } from 'next';
import * as Sentry from '@sentry/nextjs';
import DefaultFallback from './DefaultFallback';

const ErrorBoundary: NextPage = ({ children }) => (
  <Sentry.ErrorBoundary
    fallback={({ resetError }) => <DefaultFallback resetError={resetError} />}
  >
    {children}
  </Sentry.ErrorBoundary>
);

export default ErrorBoundary;
