import { SvgIcon, SvgIconProps, SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

import Icon from './icon.svg';

export const WaldoSadGlasses: OverridableComponent<
  SvgIconTypeMap<Record<string, unknown>, 'svg'>
> = (props: SvgIconProps) => (
  <SvgIcon component={Icon} viewBox="0 0 123 88" {...props} />
);
