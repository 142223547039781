import request from 'superagent';

import { API_HOST } from '../constants';
import { UserAnswer } from './types';

export const sendAnswers = async (answers: UserAnswer[]): Promise<void> => {
  const url = `${API_HOST}/v1/questions/answer`;

  await request.post(url).send(answers).withCredentials();
};
