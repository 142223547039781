import { ContentTypesKey } from '../app/contentTypes/constants';

export enum TrackableTiming {
  UNLOAD = 'Unload',
}

export enum TrackableEvent {
  ACTION = 'Action',
  FETCH = 'Fetch',
  NAVIGATION = 'Navigation',
  NO_RESULTS = 'No results',
  TIMING = 'Timing',
  UNINSTALLED_EXTENSION = 'Uninstalled extension',
}

export enum TrackableTarget {
  ABOUT = 'About',
  ADD_SOURCE_TO_LENS = 'Add source to lens',
  ADD_SOURCE_TO_SPECIAL_LENS = 'Add source to special lens',
  CLICK_CARD_DATAPOINTS = 'Click card - datapoints',
  CLICK_CARD_ENTITIES = 'Click card - entities',
  CLICK_FREQUENTLY_CITED = 'Frequently cited clicked',
  CLICK_FOLLOW_UP_QUERY = 'Click follow up query',
  CLICK_IDEATE_SUGGESTION = 'Click ideate suggestion',
  CLICK_GOOGLE_ELEMENT = 'Click google element',
  CLOSE_PANEL = 'Close panel',
  COLLAPSE_RESULTS = 'Collapse results',
  COUNTRY_FILTER = 'Country filter',
  CREATE_LENS = 'Create new lens',
  CREATE_NOTE = 'Create note',
  DATA_TYPE_FILTER = 'Data type filter',
  DATAPOINTS_SELECTED = 'Datapoints selected',
  DOWNLOAD_DATA_CSV = 'Download data - CSV',
  DATES_SELECTED = 'Dates selected',
  DELETE_NOTE = 'Delete note',
  DESELECT_SOURCE = 'Deselect source',
  DOMAINS_FILTER = 'Domains filter',
  EMAILS_SELECTED = 'Emails selected',
  ENTER_FOCUS_MODE = 'Enter focus mode',
  ENTER_SEARCH_MODE = 'Enter search mode',
  EXIT_FOCUS_MODE = 'Exit focus mode',
  EXIT_SEARCH_MODE = 'Exit search mode',
  EXPAND_EXCERPT = 'Expand excerpt',
  EXPAND_RESULTS = 'Expand results',
  EXPORT_NOTE_TO_GOOGLE_DOCS = 'Export note to Google Docs',
  FAQ = 'FAQ',
  FEEDBACK = 'Feedback',
  FETCH_API = 'API',
  FETCH_BING = 'Bing',
  FETCH_GOOGLE = 'Google',
  FILETYPE_FILTER = 'Filetype filter',
  FOCUS_POWER_SEARCH = 'Focus power search',
  FOCUS_SOURCE = 'Focus on source',
  GOOGLE_COUNTRY = 'Google country',
  GOOGLE_DATE = 'Google date',
  GOOGLE_LANGUAGE = 'Google language',
  GOOGLE_PREVIEW_SELECTED = 'Google preview selected',
  GOOGLE_SEARCH_RESULTS = 'Google search results',
  HIDE_VIEW_ALL_SOURCES = 'Hide / view all sources',
  IDEATE_START = 'Ideate start project',
  JUST_TITLES_SELECTED = 'Just titles selected',
  KEYWORDS_FILTER = 'All findings filter',
  KEYWORDS_SELECTED = 'All findings selected',
  LANGUAGE_FILTER = 'Language filter',
  LENS_FILTER = 'Lens filter',
  LINKS_SELECTED = 'Links selected',
  MORE_FILTER = 'More filter',
  NEXT_RESULT = 'Next result',
  OPEN_EXPLORE = 'Open explore box',
  OPEN_GOOGLE_MODAL = 'Open google modal',
  OPEN_GOOGLE_PAGE = 'Open google page',
  OPEN_IMAGE = 'Open image',
  OPEN_NOTEPAD = 'Open notepad',
  OPEN_PAGE = 'Open page',
  OPEN_PANEL = 'Open panel',
  OPEN_RESULT = 'Open result',
  OPEN_WALDO_FROM_GOOGLE_SEARCH_BAR = 'Open Waldo from Google search bar',
  OPEN_WALDO_FROM_GOOGLE_SHORTCUTS = 'Open Waldo from Google shortcuts',
  PHONES_SELECTED = 'Phones selected',
  PIN_NOTE = 'Pin note',
  PREVIOUS_RESULT = 'Previous result',
  PUBLISH_NOTE = 'Publish note',
  QUOTATIONS_SELECTED = 'Quotations selected',
  REGION_FILTER = 'Region filter',
  SAVE_HIGHLIGHT = 'Save highlight',
  SAVE_IMAGE = 'Save image',
  SAVE_PAGE = 'Save page',
  SCAN_PAGE = 'Scan page',
  SCROLL = 'Scroll',
  SEARCH_FOR_ENTITY = 'Search for entity',
  SELECT_SOURCE = 'Select source',
  SHOW_FEWER_ENTITIES = 'Show fewer entities',
  SHOW_MORE = 'Show More',
  SHOW_MORE_ENTITIES = 'Show more entities',
  SITE_FILTER = 'Site filter',
  SOCIAL_LINKS_SELECTED = 'Social links selected',
  TOGGLE_SETTINGS = 'Toggle settings',
  TOGGLE_TOOLS = 'Toggle tools',
  TUTORIAL_BACK = 'Tutorial - Back',
  TUTORIAL_EXIT = 'Tutorial - Exit',
  TUTORIAL_ITEM_FINISHED = 'Tutorial - Item finished',
  TUTORIAL_ITEM_SKIPPED = 'Tutorial - Item skipped',
  TUTORIAL_ITEM_STARTED = 'Tutorial - Item started',
  TYPE_IN_NOTE = 'Type in note',
  UNPIN_NOTE = 'Unpin note',
  UNPUBLISH_NOTE = 'Unpublish note',
  VIEW_HOTKEYS = 'View hotkeys',
  VIEW_MOST_CITED = 'View most cited',
  VIEW_SAVED = 'View saved',
  WONDER = 'Wonder',
  XRAY_HIGHLIGHT = 'X-Ray highlight',
  XRAY_SEARCH = 'X-Ray search',
  XRAY_SEARCH_CLEAR = 'X-Ray search - clear',
  XRAY_SEARCH_DATAPOINT_SUGGESTION = 'X-Ray search - datapoint suggestion',
  XRAY_SEARCH_ENTITY_SUGGESTION = 'X-Ray search - entity suggestion',
  XRAY_SEARCH_FOCUS = 'X-Ray search - focus',
  XRAY_TAB = 'X-Ray tab',
  XRAY_VIEW_SELECTED = 'X-Ray view selected',
}

export enum FetchMethod {
  GOOGLE = 'Google',
  GOOGLE_IFRAME = 'Google Iframe',
  BING = 'Bing',
  API = 'API',
}

export const ContentTypesTargets: Record<ContentTypesKey, TrackableTarget> = {
  keywords: TrackableTarget.KEYWORDS_SELECTED,
  dataPoints: TrackableTarget.DATAPOINTS_SELECTED,
  dates: TrackableTarget.DATES_SELECTED,
  emails: TrackableTarget.EMAILS_SELECTED,
  phones: TrackableTarget.PHONES_SELECTED,
  quotations: TrackableTarget.QUOTATIONS_SELECTED,
  socialLinks: TrackableTarget.SOCIAL_LINKS_SELECTED,
  linksWithMentions: TrackableTarget.LINKS_SELECTED,
};
