import { FunctionComponent } from 'react';

import { Button as ButtonMui, ButtonProps, styled } from '@mui/material';

export interface ButtonPrimaryProps extends ButtonProps {}

const Button = styled(ButtonMui)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  '&.checked': {
    backgroundColor: 'transparent',
    border: `1.5px solid ${theme.palette.line?.disabled}`,
    '&.MuiButton-root:not(.Mui-disabled)>*': {
      color: theme.palette.primary.main,
    },
  },
  border: 'none',
  '&.MuiButton-root:not(.Mui-disabled)': {
    color: theme.palette.common.white,
    '&.checked': {
      color: theme.palette.primary.main,
    },
  },
  '&.MuiButton-root:not(.Mui-disabled)>*': {
    color: theme.palette.common.white,
  },
  '&:hover': {
    '&.checked': {
      backgroundColor: 'transparent',
      border: `1.5px solid ${theme.palette.line?.disabled}`,
    },
    backgroundColor: theme.palette.primary.main,
    border: 'none',
  },
}));

const ButtonPrimary: FunctionComponent<ButtonPrimaryProps> = ({
  children,
  ...props
}) => <Button {...props}>{children}</Button>;

export default ButtonPrimary;
