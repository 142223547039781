import request from 'superagent';

import { API_HOST } from '../constants';
import { Question } from './types';

export const get = async (): Promise<Question[]> => {
  const url = `${API_HOST}/v1/questions`;

  const { body } = await request.get(url);

  return body;
};
