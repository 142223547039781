import request from 'superagent';

import { API_HOST } from '../constants';
import { Team } from './types';

export const getTeamByPublicInvite = async (
  inviteCode: string,
): Promise<Team> => {
  const url = `${API_HOST}/v1/teams/invite/${inviteCode}`;

  const { body } = await request.get(url).withCredentials();

  return body;
};

export const getTeamByPrivateInvite = async (
  inviteCode: string,
): Promise<Team> => {
  const url = `${API_HOST}/v1/teams/code/${inviteCode}`;

  const { body } = await request.get(url).withCredentials();

  return body;
};
