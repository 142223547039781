import request from 'superagent';

import { API_HOST } from '../constants';
import { UserNpsAnswer } from './types';

export const sendAnswers = async (answers: UserNpsAnswer[]): Promise<void> => {
  const url = `${API_HOST}/v1/nps/answer`;

  await request.post(url).send(answers).withCredentials();
};
