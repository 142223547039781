import { ReactNode } from 'react';

import { styled, SvgIcon, SvgIconProps, SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

import Icon from './icon.svg';

const StyledIcon = styled(SvgIcon)<{ component: ReactNode }>(() => ({
  height: 22,
  width: 83,
}));

export const Waldo: OverridableComponent<
  SvgIconTypeMap<Record<string, unknown>, 'svg'>
> = (props: SvgIconProps) => (
  <StyledIcon component={Icon} viewBox="0 0 83 22" {...props} />
);
