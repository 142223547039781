import { Theme, useMediaQuery } from '@mui/material';
import {
  atom,
  SetterOrUpdater,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import { useMemo } from 'react';

import { localStorageEffect } from '@/ext/app/utils/recoil';
import darkTheme from '@/styles/themes/dark';
import lightTheme from '@/styles/themes/light';

export type ValueType = 'system' | 'dark' | 'light';

export const preferredThemeState = atom<ValueType>({
  key: 'preferredTheme',
  default: 'system',
  effects: [localStorageEffect],
});

export const usePreferredThemeValue = (): ValueType =>
  useRecoilValue(preferredThemeState);

export const useSetPreferredTheme = (): SetterOrUpdater<ValueType> =>
  useSetRecoilState(preferredThemeState);

export const useSetPreferredThemeState = (): [
  ValueType,
  SetterOrUpdater<ValueType>,
] => useRecoilState(preferredThemeState);

export const usePreferredTheme = (): Theme => {
  const value = useRecoilValue(preferredThemeState);
  const systemPrefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  return useMemo(() => {
    switch (value) {
      case 'dark':
        return darkTheme;

      case 'light':
        return lightTheme;

      default:
        return systemPrefersDarkMode ? darkTheme : lightTheme;
    }
  }, [systemPrefersDarkMode, value]);
};
