/* eslint-disable no-console */

import { get } from 'lodash/fp';
import { deserializeError } from 'serialize-error';
import { v4 } from 'uuid';

import { ExternalMessage } from '../../types';
import { timeout } from './promises';
import { ensure } from '../../utils/ensure';
import { isThirdPartyCookiesEnabled } from '@/utils/isThirdPartyCookiesEnabled';

const TIMEOUT = 5000;

const DEFAULT_EXTENSION_ID = ensure(process.env.NEXT_PUBLIC_EXTENSION_ID);

const getExtensionId = () => {
  if (isThirdPartyCookiesEnabled()) {
    const OVERRIDE_EXTENSION_ID =
      typeof localStorage !== 'undefined'
        ? localStorage.getItem('OVERRIDE_EXTENSION_ID')
        : null;

    return OVERRIDE_EXTENSION_ID || DEFAULT_EXTENSION_ID;
  }

  return DEFAULT_EXTENSION_ID;
};

export const EXTENSION_ID = getExtensionId();

console.debug('👽 Extension ID:', EXTENSION_ID);

const sendMessageUsingChromeApi = async <T, R>(message: T): Promise<R> =>
  new Promise((resolve, reject) => {
    if (
      typeof chrome === 'undefined' ||
      typeof chrome.runtime === 'undefined'
    ) {
      reject(new Error('No chrome object found'));
    }

    try {
      chrome.runtime.sendMessage(EXTENSION_ID, message, (response) => {
        if (chrome.runtime.lastError) {
          reject(chrome.runtime.lastError);
        } else {
          resolve(response);
        }
      });
    } catch (error) {
      reject(error);
    }
  });

const sendMessageUsingPostMessageApi = async <T, R>(
  message: T,
  messageTimeout?: number,
): Promise<R> =>
  new Promise((resolve, reject) => {
    const id = v4();
    let responded = false;

    const handler = (event: any) => {
      if (
        event.source === window &&
        event.data &&
        event.data.id === id &&
        event.data.direction === 'from-site-script'
      ) {
        if (!responded) {
          responded = true;
          window.removeEventListener('message', handler);
          if (event.data.isError) {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject([new Error(event.data.message), undefined]);
          } else {
            resolve([undefined, event.data.message] as unknown as R);
          }
        }
      }
    };

    setTimeout(
      () => {
        if (!responded) {
          responded = true;
          window.removeEventListener('message', handler);
          // eslint-disable-next-line prefer-promise-reject-errors
          reject([new Error('Message never received'), undefined]);
        }
      },
      messageTimeout,
      handler,
    );

    window.addEventListener('message', handler);

    window.postMessage(
      {
        direction: 'from-page-script',
        id,
        message,
      },
      '*',
    );
  });

const sendChromeMessage = <T, R>(
  message: T,
  messageTimeout: number,
): Promise<R> => {
  if (typeof chrome !== 'undefined') {
    return sendMessageUsingChromeApi(message);
  }
  if (typeof window !== 'undefined') {
    return sendMessageUsingPostMessageApi(message, messageTimeout);
  }
  return Promise.resolve([
    new Error('Unable to send message to background script'),
    undefined,
  ] as unknown as R);
};

export const sendMessage = async <T, B = undefined>(
  message: ExternalMessage<B>,
  messageTimeout = TIMEOUT,
): Promise<T> => {
  console.debug('📤', message, get('value', message));
  const ret = sendChromeMessage<ExternalMessage<B>, [Error, T]>(
    message,
    messageTimeout,
  );
  const maxWait = timeout(
    messageTimeout,
    () => new Error(`Unexpected timeout for message: ${message.type}`),
  );
  const [error, result] = await Promise.race([maxWait, ret]);
  maxWait.catch((err) => [undefined, err]);

  if (error) {
    const err = deserializeError(error);
    console.debug('❌', message, err);
    throw err;
  }

  console.debug('📥', message, result);

  return result;
};
