import { useMemo } from 'react';

import { createTheme, Theme, ThemeOptions } from '@mui/material';
import { TypographyOptions } from '@mui/material/styles/createTypography';

import { usePreferredTheme } from '@/styles/themes/state';
import darkSystemTheme from '@/styles/themes/dark';
import lightSystemTheme from '@/styles/themes/light';
import system from '@/styles/themes/system';

const { breakpoints } = system;

const baseOptions: ThemeOptions = {
  typography: {
    body1: {
      fontSize: 16,
      letterSpacing: '-0.02em',
      lineHeight: 1.5,

      [breakpoints.up('sm')]: {
        fontSize: 20,
        lineHeight: 1.6,
      },
    },

    body2: {
      fontFamily: ['Georgia', 'serif'].join(','),
      fontSize: 16,
      fontStyle: 'italic',
      letterSpacing: '-0.02em',
      lineHeight: 1.5,

      [breakpoints.up('md')]: {
        fontSize: 20,
        lineHeight: 1.6,
      },
    },

    button: {
      fontSize: 14,
      fontWeight: 500,
      lineHeight: 1.14285714,
      textTransform: 'none',
    },

    caption: {
      fontFamily: ['Inter', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(
        ',',
      ),
      fontSize: 16,
      letterSpacing: '-0.02em',
      lineHeight: 1.5,
    },

    h1: {
      fontSize: 24,
      fontWeight: 800,
      letterSpacing: '-0.02em',
      lineHeight: 1.33333333,

      [breakpoints.up('md')]: {
        fontSize: 48,
        lineHeight: 1.16666667,
      },
    },

    h2: {
      fontSize: 18,
      fontWeight: 800,
      letterSpacing: '-0.04em',
      lineHeight: 1.33333333,

      [breakpoints.up('md')]: {
        fontSize: 26,
        lineHeight: 1.23076923,
      },
    },

    h3: {
      fontSize: 18,
      fontWeight: 800,
      lineHeight: 1.33333333,

      [breakpoints.up('md')]: {
        fontSize: 22,
        letterSpacing: '-0.02em',
        lineHeight: 1.45454545,
      },
    },

    h4: {
      fontSize: 18,
      fontWeight: 400,
      lineHeight: 1.16666667,

      [breakpoints.up('md')]: {
        fontSize: 22,
        fontWeight: 500,
        letterSpacing: '-0.02em',
        lineHeight: 1.45454545,
      },
    },

    h5: {
      fontSize: 17,
      fontWeight: 400,
      lineHeight: 1.27272727,

      [breakpoints.up('md')]: {
        fontSize: 20,
        fontWeight: 800,
        letterSpacing: '-0.03em',
        lineHeight: 1.6,
      },
    },

    h6: {
      fontSize: 16,
      fontWeight: 800,
      letterSpacing: '-0.02em',
      lineHeight: 1.33333333,

      [breakpoints.up('md')]: {
        fontSize: 16,
        lineHeight: 1.5,
      },
    },

    overline: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: 1.14285714,
      textTransform: 'none',

      [breakpoints.up('md')]: {
        letterSpacing: '-0.02em',
      },
    },
  },
};

export default createTheme(system, baseOptions);

export const dark = createTheme(darkSystemTheme, baseOptions);
export const light = createTheme(lightSystemTheme, baseOptions);

export const usePreferredNotesTheme = (removeMediaQueries = true): Theme => {
  const preferredTheme = usePreferredTheme();
  const options = { ...baseOptions };
  const typography = options.typography as TypographyOptions;

  return useMemo(() => {
    if (removeMediaQueries && typography) {
      Object.keys(typography).forEach((k) => {
        const key = k as keyof TypographyOptions;

        if (typography[key] && typeof typography[key] === 'object') {
          // TODO: wtf typing...
          // @ts-ignore
          typography[key] = Object.fromEntries(
            Object.entries(typography[key] as Record<string, unknown>).filter(
              ([kk]) => !kk.startsWith('@media'),
            ),
          );
        }

        options.typography = typography;
      });
    }

    return createTheme(preferredTheme, options);
  }, [preferredTheme, removeMediaQueries]);
};
