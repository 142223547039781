import { alpha, PaletteOptions } from '@mui/material';

export const base = '#FFFFFF';

const colors: PaletteOptions = {
  background: {
    contrast: '#333437',
    default: '#28292C',
    menu: '#333437',
    paper: '#18191A',
  },

  primary: {
    contrastText: base,
    light: '#48A2A2',
    main: '#2DB4B4',
  },

  secondary: {
    contrastText: base,
    light: alpha('#F2F7F7', 0.07),
    main: alpha('#F2F7F7', 0.1),
    dark: alpha('#F2F7F7', 0.15),
  },

  tertiary: {
    contrastText: base,
    dark: '#9A164E',
    main: '#D40A60',
    light: '#FDC9DF',
  },

  text: {
    primary: alpha(base, 0.85), // #FFFFFF - 85%
    secondary: alpha(base, 0.65), // #FFFFFF - 65%
    disabled: alpha(base, 0.4), // #FFFFFF - 40%
    contrast: base,
  },

  helper: alpha(base, 0.5), // #FFFFFF - 50%

  error: {
    main: alpha('#F0428C', 0.9),
  },

  fill: {
    high: alpha(base, 0.85), // #FFFFFF - 85%
    medium: alpha(base, 0.15), // #FFFFFF - 15%
    disabled: alpha(base, 0.05), // #FFFFFF - 5%
  },

  icon: {
    high: alpha(base, 0.65), // #FFFFFF - 65%
    medium: alpha(base, 0.4), // #FFFFFF - 40%
    disabled: alpha(base, 0.25), // #FFFFFF - 25%
  },

  info: {
    contrastText: '#FFFFFF',
    dark: alpha('#F0428C', 0.9),
    light: '#FAC034',
    main: '#78A8DB',
  },

  line: {
    high: alpha(base, 0.3), // #FFFFFF - 30%
    medium: alpha(base, 0.2), // #FFFFFF - 20%
    disabled: alpha(base, 0.1), // #FFFFFF - 10%
  },

  warning: {
    contrastText: '#FFFFFF',
    light: '#E3AC38',
    main: alpha('#F0428C', 0.9),
  },
};

export default colors;
