import { useCallback } from 'react';

import { TrackableEvent, TrackableTarget } from '../../lib/trackable';
import { trackUserEvent, Meta } from '../../lib/tracking';
import { getRoot } from '../utils/dom';
import { getPageId } from '../utils/pageId';
import { useEventListener } from '../utils/useEventListener';
import { useRecordActionDate } from './actionDates';
import { throttle100ms, throttle30s } from '../utils/throttle';

type TrackingFunction = <T extends Meta = Meta>(
  event: string,
  meta?: T,
) => void;

export const useTracking = (): TrackingFunction => {
  const recordActionDate = useRecordActionDate();

  return useCallback(
    (event, meta) => {
      trackUserEvent(event, {
        ...meta,
        pageId: getPageId(),
      });
      if (event === TrackableEvent.ACTION) {
        recordActionDate(new Date());
      }
    },
    [recordActionDate],
  );
};

/**
 * Track interaction with Waldo.
 */
export const useTrackActions = (): void => {
  const root = getRoot();
  const trackEvent = useTracking();

  useEventListener(
    'scroll',
    throttle30s((e: MouseEvent) => {
      if (e.target !== window.document) {
        return;
      }

      trackEvent(TrackableEvent.ACTION, {
        target: TrackableTarget.SCROLL,
      });
    }),
    root,
    true,
  );

  useEventListener(
    'click',
    throttle100ms((e: MouseEvent) => {
      if (e.target instanceof Element) {
        const tracker = e.target.closest<HTMLElement>('[data-tracker]');
        const { tracker: target, ...data } = tracker?.dataset || {};

        if (!target) {
          return;
        }

        trackEvent(TrackableEvent.ACTION, {
          ...data,
          target,
        });
      }
    }),
    root,
    true,
  );
};
