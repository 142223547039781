import request from 'superagent';

import { API_HOST } from '../constants';
import { TeamMember } from './types';

export const joinTeam = async (inviteCode: string): Promise<TeamMember> => {
  const url = `${API_HOST}/v1/teams/join/${inviteCode}`;

  const { body } = await request.post(url).withCredentials();

  return body;
};
