import { flow, sortBy, takeRight, uniq } from 'lodash/fp';
import { atom, selector, useRecoilCallback } from 'recoil';

import { apiStorageEffect } from '../utils/recoil';

const actionDatesInternalState = atom<string[]>({
  key: 'actionDatesInternalState',
  default: [],
  effects: [apiStorageEffect],
});

export const actionDatesState = selector<Date[]>({
  key: 'actionDatesState',
  get: ({ get }) => get(actionDatesInternalState).map((s) => new Date(s)),
});

/**
 * Records a day as actioned day
 */
export const useRecordActionDate = (): ((date: Date) => void) =>
  useRecoilCallback(
    ({ set, snapshot }) =>
      (date) => {
        const currValue = snapshot
          .getLoadable(actionDatesInternalState)
          .getValue();
        const newValue = flow(
          uniq,
          sortBy((a: string) => new Date(a)),
          takeRight(30),
        )([...currValue, date.toLocaleDateString('en-US')]);
        return set(actionDatesInternalState, newValue);
      },
    [],
  );
