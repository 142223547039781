export class WaldoVersion {
  raw: string;

  major: number;

  minor: number;

  patch: number;

  constructor(raw: string) {
    this.raw = raw;

    let toParse = '0.0.0';
    if (raw.match(/^\d+\.\d+\.\d+(.\d+)?$/)) {
      toParse = raw;
    }

    const [major, minor, patch] = toParse.split('.');
    this.major = parseInt(major, 10);
    this.minor = parseInt(minor, 10);
    this.patch = parseInt(patch, 10);
  }

  get = (): { major: number; minor: number; patch: number } => ({
    major: this.major,
    minor: this.minor,
    patch: this.patch,
  });

  toString = (): string => this.raw;
}
