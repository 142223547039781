import { DebouncedFunc, throttle } from 'lodash';

const THIRTY_SECONDS_MS = 30 * 1000;
const ONE_HUNDRED_MS = 100;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const throttle30s = <T extends (...args: any[]) => any>(
  func: T,
): DebouncedFunc<T> =>
  throttle(func, THIRTY_SECONDS_MS, {
    trailing: false,
  });

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const throttle100ms = <T extends (...args: any[]) => any>(
  func: T,
): DebouncedFunc<T> =>
  throttle(func, ONE_HUNDRED_MS, {
    trailing: false,
  });
